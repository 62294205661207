import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import PromotionService from '@/services/PromotionService';

@Mixin
export default class CreditTurnMixin extends Vue {
  public creditTurn: any = {};

  public promotionService = new PromotionService();

  async created() {
    const { data } = await this.promotionService.checkTurnover();
    this.creditTurn = { ...data };
  }
}
