import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import TransactionService from '@/services/TransactionService';

@Mixin
export default class TransactionMixin extends Vue {
  [x: string]: any;

  public loading: boolean = false;

  private transactionService = new TransactionService();

  public async submitDepositRequest() {
    try {
      this.loading = true;
      const data = this.getFormData();
      await this.transactionService.requestDeposit(data);
      this.resetFormData();
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเรียบร้อยแล้ว', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }

  public async fetchTransaction(type: string) {
    const { data } = await this.transactionService.getTransaction(type);
    return data;
  }

  public async submitWithdrawRequest(refreshCallback) {
    try {
      this.loading = true;
      const data = this.getFormData();
      await this.transactionService.requestWithdraw(data);
      this.resetFormData();
      refreshCallback();
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเรียบร้อยแล้ว', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }
}
